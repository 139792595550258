<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body p-0">
					<div class="col-auto d-flex justify-content-end align-items-end px-0">
						<DownloadButton v-if="tableData?.length" :downloading="downloadingReport" label="Download Report"
							@download="downloadReport()" />
					</div>
					<v-server-table ref="companiesTable" selectable :columns="columns" :options="options"
						@loading="tableLoading = true" @loaded="tableLoading = false" @row-click="onRowClicked">
						<span slot="id" slot-scope="props">
							<span>{{ props?.index }}</span>
						</span>

						<div slot="corporate_name" slot-scope="props">
							<router-link :to="{
								name: 'ShowCompanyInfo',
								params: { companyId: props?.row?.id, status: 'active' },
							}">
								{{ props?.row?.corporate_name }}
							</router-link>
						</div>
					</v-server-table>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
import { extractErrorMessage } from '@/utils/helpers'
import { ref, computed, inject } from 'vue'
import { axiosInstance } from '@/plugins/axios'
import { useRouter } from 'vue-router/composables'
import Swal from 'sweetalert2'

const router = useRouter()
const tableLoading = ref(false)
const totalRecords = ref(0)
const companies = ref(null)
const downloadingReport = ref(false)
const tableData = ref([])
const columns = ref(['id', 'corporate_name', 'email', 'corporate_phone'])
const options = ref({
  filterable: true,
  requestFunction: async (data) => {
    try {
      return await fetchCorporates(data)
    } catch (e) {  }
  }
})

const date = computed((value) => {
  if (!value) return ''
  const options = { year: 'numeric', month: 'long', day: 'numeric' }
  return Intl.DateTimeFormat('en-US', options).format(value)
})


const onRowClicked = ({ row: company }) => {
  router.push({
    name: 'ShowCompanyInfo',
    params: { companyId: company.id, status: 'active' }
  })
}

const fetchCorporates = async (params) => {
  tableLoading.value = true
  const result = await axiosInstance.get(
    `/v1/corporates?new_signup=${false}${params?.query ? '&search=' + params.query : ''
    }`,
    { params }
  )
  tableData.value = result?.data?.data
  totalRecords.value = result?.data?.metadata?.total
  const newResult = {
    ...result.data,
    data: result?.data?.data,
    count: result?.data?.metadata?.total
  }
  tableLoading.value = false
  return {
    data: newResult
  }
}

const downloadReport = async () => {
  try {
    downloadingReport.value = true
    const result = await axiosInstance.get(
      `/v1/corporates?limit=${totalRecords.value}`
    )
    const csvData = result?.data?.models.map((data) => {
      return {
        phone: data?.corporate_phone,
        name: data?.corporate_name,
        email: data?.person_email
      }
    })

    const csvParams = {
      fieldSeparator: ',',
      filename: 'Company List',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Company List',
      useTextFile: false,
      useBom: true,
      headers: ['Companies Phone', 'Companies Name', 'Companies Emails']
    }

    const csvExporter = new ExportToCsv(csvParams)

    csvExporter.generateCsv(csvData)

      Swal.fire({
      icon: 'success',
      title: 'Report downloaded',
      text: 'Report has been downloaded successfully',
      showCloseButton: true
    })
  } catch (error) {
      Swal.fire({
      icon: 'error',
      title: 'An error occurred!',
      text: extractErrorMessage(error, 'Failed to download report'),
      showCloseButton: true
    })
  } finally {
    downloadingReport.value = false
  }
}

</script>

<style lang="scss" scoped></style>
